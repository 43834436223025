<template>
  <div id="salesStatistics">
    <h2>运营额统计</h2>
    <echarts-com id="chart" :options="options"/>
  </div>
</template>

<script>
import * as echarts from "echarts";
import echartsCom from "@/components/echartsCom";
import {formatRatio} from "@/utils/util";
export default {
  components: {
    echartsCom
  },
  data() {
      return {
        options:{}
      }
  },
  created() {
    this.getOptions()
  },
  methods:{
    async getOptions(){
      try {
        let res = await this.$http.post("/home/sales/rank", {
          type: "今日",
        });
        if (res.code === 0) {
          let xAxis_data = []
          let values = []
          for(let item of res.data.trendList){
            xAxis_data.push(item.date)
            values.push(item.value)
            this.$set(this, "options", {
              tooltip: {
                trigger: "axis",
                extraCssText: "box-shadow: 0px 0px 13px rgba(0, 193, 153, 0.22);",
                padding: [15, 10],
                formatter: (params) => {
                  return `<div>
                    <span style="display: inline-block;width:10px;height:10px;border-radius: 50%;background: linear-gradient(360deg, #12D5CE 0%, #12D5CE 0%, #2EB6CF 98%, #2EB6CF 100%);"></span>
                    <span>${params[0].name}: ${params[0].value}</span>
                </div>`;
                },
              },
              xAxis: {
                type: "category",
                data: xAxis_data,
                axisTick: {
                  show: false,
                },
                axisLine: {
                  lineStyle: {
                    color: "rgba(255,255,255,0.09)",
                  },
                },
                axisLabel: {
                  color: "white",
                },
              },
              yAxis: {
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "rgba(255,255,255,0.09)",
                  },
                },
                axisLine: {
                  show: false,
                },
                axisLabel: {
                  color: "white",
                },
              },
              grid: {
                left: "0%",
                width: "100%",
                height: "90%",
                bottom: "0%",
                containLabel: true, // 包含坐标轴中的文字
              },
              series: [
                {
                  type: "bar",
                  barMaxWidth: 16,
                  itemStyle: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {offset: 0, color: "rgba(52,152,255,1)"},
                      {offset: 1, color: "rgba(52,152,255,0.3)"},
                    ]),
                  },
                  data: values,
                },
              ],
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      } catch {
        this.$message.error("停车场列表获取失败");
      }
    }
  }
}
</script>

<style lang="scss">
#salesStatistics {
  height: 30%;
  margin-bottom: 2.8rem;
  #chart{
    width: 100%;
    height: 85%;
    background: transparent;
  }
}
</style>
